import React, { FC } from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/Layout'
import { Box, Container } from 'styles/globalStyle'
import { Title, Text, Accordion, RichText } from 'components/theme'
import { FAQPageTypes } from 'types/faqPageTypes'

type FAQPageParams = {
  data: FAQPageTypes
}

const FAQPage: FC<FAQPageParams> = ({ data }) => {
  const { title, subheading, subtitle, body } = data.prismicFaqPage.data

  return (
    <Layout>
      <Container my="6rem" textAlign="center">
        <Text fontWeight="600" color="primary.600" mb="0.75rem">
          {subheading.text}
        </Text>
        <Title size="lg" mb="1.5rem">
          {title.text}
        </Title>
        <Text size="xl">{subtitle.text}</Text>
        <Box maxWidth={['100%', '60%']} marginX="auto">
          {body.map((item, idx) => (
            <Box mt="4rem" textAlign="left" key={idx}>
              <Title as="h2" color="primary.500">
                {item.primary.title.text}
              </Title>
              {item.items.map((faqItem, index) => (
                <Accordion title={faqItem.question.text} key={index} isFAQ>
                  <RichText content={faqItem.response} />
                </Accordion>
              ))}
            </Box>
          ))}
        </Box>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    prismicFaqPage {
      data {
        title {
          text
        }
        subtitle {
          text
        }
        subheading {
          text
        }
        body {
          ... on PrismicFaqPageDataBodyFaqSection {
            items {
              response {
                richText
              }
              question {
                text
              }
            }
            primary {
              title {
                text
              }
            }
          }
        }
      }
    }
  }
`

export default FAQPage
